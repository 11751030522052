<template>
  <AppContainer>
    <div class="setting-area">
      <!-- Policies -->
      <CurrentSubPlan />
      <Policy />
      <QueueSettings />
    </div>
  </AppContainer>
</template>

<script>
import AppContainer from "@/components/containers/DashboardContainer.vue";
import Policy from "@/components/dashboard/settings/Policy.vue";
import QueueSettings from "@/components/dashboard/settings/QueueSettings.vue";
import CurrentSubPlan from '@/components/dashboard/settings/CurrentSubPlan.vue';
export default {
  components: {
    AppContainer,
    Policy,
    QueueSettings,
    CurrentSubPlan,
  },
};
</script>