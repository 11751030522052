<template>
  <div class="funnel-area">
    <div class="row">
      <div class="col-12">
        <div class="funnel-box">
          <!-- Dealership -->
          <div class="accordion" id="cancilationPolicyAcc">
            <div class="card">
              <div class="card-header" id="cancilationPolicyHeading">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne30"
                    aria-expanded="true"
                    aria-controls="collapseOne30"
                  >
                    <div class="col-12 detais-title">
                      <h3>
                        <img
                          class="img-fluid arrow-down"
                          src="../../../assets/images/dashboard/arrow-d.svg"
                          alt=""
                        />
                        <div class="funnel-title">
                          <h3>Cancellation / No-Show Policy</h3>
                          <!-- <p class="pt-2">
                            Settings for cancel the booking and no show
                          </p> -->
                        </div>
                      </h3>
                    </div>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne30"
                class="collapse show"
                aria-labelledby="cancilationPolicyHeading"
                data-bs-parent="#cancilationPolicyAcc"
              >
                <div class="card-body">
                  <div class="policy-table">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th style="width: 300px">Title</th>
                          <th style="width: 350px">Description</th>
                          <th class="text-center">Action</th>
                        </tr>
                        <tr v-for="(policy, index) in policies" :key="index">
                          <td class="policy-title">{{ policy.title }}</td>
                          <td class="policy-desc">{{ policy.description }}</td>
                          <td class="text-center">
                            <a href="javascript:;"><span v-on:click="editPolicy(policy)">Edit</span> </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--End Dealership -->

          <!-- <div class="funnel-title">
            <h3>Cancellation Policies</h3>
            <p class="pt-2">Settings for cancel the booking and no show</p>
          </div>
          <div class="policy-table">
            <table class="table">
              <tbody>
                <tr>
                  <th style="width: 275px">Title</th>
                  <th>Description</th>
                  <th class="text-center">Action</th>
                </tr>
                <tr v-for="(policy, index) in policies" :key="index">
                  <td class="policy-title">{{ policy.title }}</td>
                  <td class="policy-desc">{{ policy.description }}</td>
                  <td class="text-center">
                    <fa
                      :icon="['far', 'edit']"
                      v-on:click="editPolicy(policy)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="policyModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="policyModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="policyModalLabel">
              Update
              <span v-if="policy_.modal_type == 'cancel' && policy_.booking_type == '1'">Queue Cancellation</span>
              <span v-if="policy_.modal_type == 'no-show' && policy_.booking_type == '1'">Queue No Show</span> 
              <span v-if="policy_.modal_type == 'cancel' && policy_.booking_type == '2'">Booking Cancellation</span>
              <span v-if="policy_.modal_type == 'no-show' && policy_.booking_type == '2' ">Booking No Show</span> Policy
            </h5>
            <a class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../../assets/images/icons/Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="add-member">
              <div class="form-row">
                <div class="form-group col-6">
                  <label
                    class="con-company"
                    :class="policy_.type == '1' ? 'currnt' : ''"
                    >I Don't want to charge
                    <input
                      type="radio"
                      v-model="policy_.type"
                      value="1"
                      v-on:change="makeStatement"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group col-6">
                  <label
                    class="con-company"
                    :class="policy_.type == '2' ? 'currnt' : ''"
                    >I want to charge
                    <input
                      type="radio"
                      v-model="policy_.type"
                      value="2"
                      v-on:change="makeStatement"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div
                  class="form-group col-12 col-md-8"
                  v-if="policy_.modal_type == 'cancel' && policy_.type == '2' && tpolicy_.booking_type == '2'"
                >
                  <label for="inputEmail4"
                    >When can a customer cancel a booking without any
                    charges?</label
                  >
                  <select
                    class="form-control"
                    v-model="policy_.duration"
                    v-on:change="makeStatement"
                  >
                    <option value="">Choose Duration</option>
                    <option value="1">Within 24hrs</option>
                    <option value="2">Within 2 Days</option>
                    <option value="3">Within 3 Days</option>
                    <option value="7">Within 7 Days</option>
                    <option value="15">Within 15 Days</option>
                    <option value="30">Within 30 Days</option>
                  </select>
                </div>
                <div class="form-group col-md-7" v-if="policy_.type == 2">
                  <label for="inputEmail4"
                    >Specify the value, % of the total price</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="policy_.charge_val"
                      class="form-control"
                      placeholder="0"
                      style="width: auto"
                      @keyup="makeStatement"
                    />
                    <span class="input-group-text">%</span>
                  </div>
                </div>
                <p class="policy-statement">{{ policy_.statement }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="add-btn" data-bs-dismiss="modal">Cancel</button>
            <button v-on:click="updatePolicy()" class="rem-btn">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      policies: [],
      policy_: {
        id: "",
        type: 0,
        duration: "",
        charge_val: "",
        modal_type: "",
        statement: "",
        booking_type:"",
        business_id:
          this.$storage.getStorageSync("business").active_business.id,
      },
    };
  },
  created() {
    this.getPolicies();
  },
  methods: {
    getPolicies() {
      let thiss = this;
      axios
        .get(
          "get_policies/" +
            this.$storage.getStorageSync("business").active_business.id
        )
        .then(function (response) {
          if (response.data.status == "success") {
            thiss.policies = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    updatePolicy() {
      console.log(this.policy_);
      let thiss = this;
      axios
        .post("update_policy", this.policy_)
        .then(function (response) {
          if (response.data.status == "success") {
            thiss.getPolicies();
            $("#policyModal").modal("hide");
            thiss.toast.success(response.data.message);
            thiss.policy_.id = "";
            thiss.policy_.type = 0;
            thiss.policy_.duration = "";
            thiss.policy_.charge_val = "";
            thiss.policy_.modal_type = "";
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.toast.error(error.response.data.message);
            } else if (error.response.status == 401) {
              this.toast.error(error.response.data.message, {
                position: "top-right",
              });
            }
          }
        });
    },
    editPolicy(policy) {
      this.policy_.id = policy.id;
      this.policy_.type = policy.policy_condition;
      this.policy_.duration = policy.duration;
      this.policy_.booking_type = policy.booking_type;
      if (!policy.duration) {
        this.policy_.duration = "";
      }

      this.policy_.charge_val = policy.charge_amount;
      this.policy_.modal_type = policy.policy_type;
      $("#policyModal").modal("show");
      this.makeStatement();
    },
    makeStatement() {
      if (this.policy_.modal_type == "cancel") {
        if (this.policy_.type == 1) {
          this.policy_.statement =
            "Your client can cancel without any charge.";
        } else if (this.policy_.type == 2) {
          let duration = "";
          let amount = "";
          if (this.policy_.duration == 1) {
            duration = "24hours";
          } else if (this.policy_.duration == 2) {
            duration = "48hours";
          } else {
            duration = this.policy_.duration + " Days";
          }
          if (this.policy_.charge_val != null) {
            amount = this.policy_.charge_val;
          }
          this.policy_.statement =
            "The client will be charged " +
            amount +
            "% of the total price if canceled after  " +
            duration +
            " hours of booking.";
        }
      } else if (this.policy_.modal_type == "no-show") {
        if (this.policy_.type == 1) {
          this.policy_.statement =
            "No charges if the client does not show up.";
        } else if (this.policy_.type == 2) {
          let amount = "";
          if (this.policy_.charge_val != null) {
            amount = this.policy_.charge_val;
          }
          this.policy_.statement =
            "If the client doesn't show up " +
            amount +
            "% of the service price will be charged.";
        }
      }
    },
    // deletePolicy(id){
    //     let thiss = this
    //     this.$swal({
    //         title: 'Are you sure?',
    //         text: "You want to delete this policy!",
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         reverseButtons: true,
    //         customClass: {
    //             confirmButton: 'rem-btn',
    //             cancelButton: 'add-btn mr-3'
    //         },
    //         buttonsStyling: false
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             axios.get('delete_policy/'+id+'/'+this.$storage.getStorageSync('business').active_business.id)
    //             .then(function (response) {
    //                 if (response.data.status == 'success') {
    //                     thiss.toast.success(response.data.message);
    //                     thiss.getPolicies()
    //                 }
    //             })
    //             .catch(error => {
    //                 if (error.response) {
    //                     if (error.response.status == 422) {
    //                         this.toast.error(error.response.data.message);
    //                     }else if (error.response.status == 401) {
    //                         this.toast.error(error.response.data.message,{position: "top-right"});
    //                     }
    //                 }
    //             });
    //         }
    //     })
    // }
  },
};
</script>
<style scoped>
.funnel-box {
  padding: 15px;
}
.funnel-title {
  display: block;
  margin: 0;
}
.funnel-title h3 {
  font-size: 20px;
}
.policy-table {
  padding: 5px;
}
.policy-table .table th {
  font-size: 16px;
  font-weight: 700;
  border-top: 0;
}
.policy-title {
  font-weight: 700;
  font-size: 14px;
}
.policy-desc {
  font-size: 14px;
  color: #787878;
  font-weight: 600;
}
.policy-statement {
  padding: 10px 12px;
  background-color: #e4e8ef;
  color: #7f9abe;
  width: 100%;
  font-size: 14px;
}

.note {
  margin-top: 20px;
  font-size: 13px;
}
.note span {
  font-weight: 800;
  font-size: 14px;
}
svg {
  cursor: pointer;
}
@media screen and (max-width: 390px) {
  .policy-table .table th {
    font-size: 12px;
  }
  .policy-title {
    font-size: 12px;
  }
  .policy-desc {
    font-size: 12px;
  }
}
</style>