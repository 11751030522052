<template>
  <div class="Dealership">
    <!-- Intergrations -->
    <div class="accordion" id="subscriptionPolicy">
      <div class="card">
        <div class="card-header" id="subscriptionPolicyHeading">
          <h2 class="mb-0">
            <button class="btn btn-link" type="button" data-bs-target="#collapseOne32" aria-expanded="true" aria-controls="collapseOne32">
              <div class="detais-title funnel-title">
                <h3>
                  <img class="img-fluid arrow-down"
                    src="../../../assets/images/dashboard/arrow-d.svg"
                    alt=""
                  />Subscriptions <span class="ms-2 termsOfUse"><a href="#">(Terms of use)</a></span>
                </h3>
              </div>
            </button>
          </h2>
        </div>
        <div id="collapseOne32" class="collapse show" aria-labelledby="subscriptionPolicyHeading" data-bs-parent="#subscriptionPolicy">
          <div class="card-body">
            <div class="row">
              <div class="col-6" v-if="store.planData.payment_method === 1">
                <div v-if="store.planData.free_trial === 1">
                  <p class="mt-2 fw-bold fs-5">You are on <strong class="fs-4 text-success">{{store.planData.plan_days}} Days Free Trial.</strong>  </p>
                  <p>Free Trial Remaining Days: <strong>{{remainingDays}} Days</strong></p>
                  <p>Trial Expiry Date: <strong>{{planExpiryDate}}</strong></p>
                </div>
                <div class="payAsYouEarn mt-4">
                  <h5 class="card-title fw-bold">Pay as you earn</h5>
                  <span class="fs-5 fw-bold mb-3 text-danger">10%+50C</span><span class="text-muted"> per transaction</span>
                  <router-link :to="{name:'updateSubscriptionPlan',params:{action:'subscribe'} }" class="rem-btn mt-2 subscribeNowbtn">Subscribe Now</router-link>
                </div>
              </div>

              <div class="col-6" v-if="store.planData.payment_method === 2">
                <div class="subscription lh-lg">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="card-title fw-bold">Subscription Plan</h5>
                    </div>
                    <div class="col-6">
                      <span class="fs-4 fw-bold">${{store.planData.plan.amount}}</span><span class="text-muted"> per month</span>
                      <p class="mt-2">Remaining Days: <strong>{{remainingDays}} Days</strong></p>
                      <h6>Next billing date - <strong>{{planExpiryDate}}</strong> </h6>
                    </div>
                    <div class="col-6 border-start pt-4">
                      <h6 class="fw-bold">Total No of Employees: {{store.planData.plan.no_of_employees}}</h6>
                      <div class="progress remaining-days-bar" v-if="store.planData.plan.no_of_employees < 7">
                        <div class="progress-bar bg-success"  v-bind:style="{ width: store.planData.total_employees >= store.planData.plan.no_of_employees ? '100%':employeesPercentage+'%'}">
                          <span v-if="store.planData.total_employees >= store.planData.plan.no_of_employees">100 %</span>
                          <span v-else>{{employeesPercentage}}%</span>
                        </div>
                      </div>
                      <p><strong>{{store.planData.total_employees}} of</strong> <strong v-if="store.planData.plan.no_of_employees < 7"> {{store.planData.plan.no_of_employees}} </strong> <strong v-else> &infin; </strong> Employees Registered</p>
                    </div>
                    <div class="col-12 mb-2" v-if="store.planData.plan.no_of_employees < 7">
                      <router-link :to="{name:'updateSubscriptionPlan',params:{action:'upgrade'} }" class="rem-btn mt-3">Upgrade</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="store.planData.payment_method === 2">
                <div class="cardInfo">
                  <div class="row">
                      <div class="col-12">
                        <h5 class="fw-bold">Payment Information</h5>
                      </div>
                      <div class="col-12">
                        <div class="big-switch mt-4 mb-2">
                            <button type="button" :class="store.planData.user_settings.auto_renew_plan === 1 ? 'active':''" v-on:click="store.updateAutoRenewStatus(1)">Auto Pay</button>
                            <button type="button" :class="store.planData.user_settings.auto_renew_plan === 0 ? 'active':''" v-on:click="store.updateAutoRenewStatus(0)">Pay On Your Own</button>
                        </div>
                      </div>
                      <div class="col-12 mt-3" v-if="store.planData.user_settings.auto_renew_plan === 1">
                        <h6>Payment Method:</h6>
                      </div>
                      <div class="col-8 my-4" v-if="store.planData.user_settings.auto_renew_plan === 1">
                        <!-- {{store.planData.user_settings.card}} -->
                        <img v-if="store.planData.user_settings.card.brand == 'Visa'" src="@/assets/images/cards/card5.jpg" class="me-1 me-sm-3 align-middle">
                        <img v-else-if="store.planData.user_settings.card.brand == 'MasterCard'" src="@/assets/images/cards/card2.jpg" class="me-1 me-sm-3 align-middle">
                        <img v-else src="@/assets/images/cards/default.jpg" class="me-1 me-sm-3 align-middle">
                        <span>{{store.planData.user_settings.card.brand}} Ending in *** *** **** {{store.planData.user_settings.card.last_four}}</span>
                      </div>
                      <div class="col-4 my-4" v-if="store.planData.user_settings.auto_renew_plan === 1">
                        <button class="add-btn change-card-btn" data-bs-toggle="modal" data-bs-target="#changeCardModal"> Change</button>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <h5 class="fw-bold my-4">Subscription Transaction History:</h5>
                <div class="payment-table">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th class="payment-title">Transaction ID</th>
                        <th class="payment-title text-center">Payment Method</th>
                        <th class="payment-title text-center">No Of Employees</th>
                        <th class="payment-title text-center">Date</th>
                        <th class="payment-title text-center">Amount</th>
                        <th class="payment-title text-center">Transaction Fee</th>
                      </tr>
                      <tr>
                        <td>{{transactionHistory.transaction_id}}</td>
                        <td class="text-center">{{transactionHistory.payment_method}}</td>
                        <td class="text-center">{{transactionHistory.no_of_employees}}</td>
                        <td class="text-center">{{transDate}}</td>
                        <td class="text-center">${{transactionHistory.total_price}}</td>
                        <td class="text-center">${{transactionHistory.transaction_fee}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Change Card Modal -->
    <div class="modal fade large" id="changeCardModal" tabindex="-1" role="dialog" aria-labelledby="changeCardModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changeCardModalLabel">Change Card</h5>
                    <span title="Close">
                        <a class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">
                                <img class="img-fluid" src="@/assets/images/dashboard/close.svg" alt="" />
                            </span>
                        </a>
                    </span>
                </div>
                <div class="modal-body pt-0">
                    <StripeCard :cardData="updateCard" v-if="store.planData.user_settings.auto_renew_plan === 1"  />
                </div>
                <div class="modal-footer" >
                    <a href="#" class="add-btn float-start" data-dismiss="modal" aria-label="Close">Cancel</a>
                    <button class="rem-btn float-end" v-on:click="changeCard"> Update</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Booking Detail Modal -->
  </div>
</template>
<script>
import StripeCard from '@/components/common/StripeCard.vue'
import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
import { useToast } from "vue-toastification";
import {useSubscriptionStore} from '@/stores/subscription'
export default {
  setup(){
    const toast = useToast();
    const store = useSubscriptionStore()
    return {store,toast}
  },
  components:{
    StripeCard
  },
  data(){
    return{
      card: '',

      //nextBillingDate:'',
      //remainingDaysBar:'',
      transactionHistory:{},
      transDate:'',
    }
  },
  created(){
    this.store.planDetail(this.$storage.getStorageSync('business').active_business.id)
    this.store.getPlansList()
  },
  mounted() {

    //this.getTransactionHistory();
  },
  methods: {
    updateCard(data){
      this.card = data
    },
    async changeCard(){
      if (this.card != '') {
        const resp = await this.store.updateAutoRenewCard(this.card)
        if (resp.status == 'success') {
          this.store.planDetail(this.$storage.getStorageSync('business').active_business.id)
          $("#changeCardModal").modal('hide')
          this.toast.success(resp.message);
        }else{
          this.toast.error(resp.message);
        }
      }else{
        this.toast.error("Please choose any card.");
      }
    },
    getTransactionHistory(){
        let thiss= this
        axios.get('Transaction_detail/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
            // console.log(response.data.data)
            if (response.data.status == 'success') {
                thiss.transactionHistory = response.data.data
                thiss.transDate = new Date(thiss.transactionHistory.date).toISOString().slice(0, 10)
            }
        })
        .catch(error => {
            console.log(error.response.message)
        });
    },
  },
  computed:{
    remainingDays(){
      if (this.store.planData.free_trial == 1 || this.store.planData.payment_method == 2) {
        let endDate = new Date(this.store.planData.plan_expiry_date)
        let currentDate = new Date()
        const days = new Date(endDate - currentDate).getDate()
        //console.log("Remaining ",days)
        return days
      }else{
        return 0
      }
    },
    planExpiryDate(){
      return moment(this.store.planData.plan_expiry_date).format('MMM DD, YYYY')
    },
    employeesPercentage(){
      let per = ((this.store.planData.total_employees / this.store.planData.plan.no_of_employees)*100).toFixed()
      if(per >= 100){
          per = 100;
      }
      return per;
    }
  }
}
</script>

<style scoped>
.change-card-btn{
    font-size: 12px;
    font-weight: 600;
    padding: 7px 18px;
}

.remaining-days-bar{
    height: 8px;
    width: 100%;
}
.remaining-days-bar .progress-bar{
  background-color:#6e317a !important;
  font-size: 8px;
}


.detais-title h3{
  font-size: 20px;
}
.payment-table{
  padding: 5px;
}
.payment-title{
  font-size: 14px;
  font-weight: 700;
}
.big-switch button {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 8px 25px;
  cursor: pointer;
  float: left;
  font-weight: 700;
  font-size: 10px;
}
.big-switch:after {
  content: "";
  clear: both;
  display: table;
}
.big-switch button:first-child {
  border-right: none !important;
  border-radius: 50px 0 0 50px;
}
.big-switch button:last-child {
  border-left: none !important;
  border-radius: 0 50px 50px 0;
}

.big-switch button:hover{
  background-color: rgba(238, 238, 238, 0.388);;
}
.big-switch button.active{
  background-color: #28a745;
  color: white;
  border: 1px solid #28a745;
}
.subscribeNowbtn{
    position: absolute;
    right: 40px;
    bottom: 44px;
}
a:hover{
    color: #6e317a;
    background: #fff;
    font-weight: bold;
}
.termsOfUse a {
    text-decoration: none;
    font-size: 11px;
    color: #6e317a;
}
.termsOfUse a:hover{
    text-decoration: underline;
}

.payAsYouEarn,.subscription, .cardInfo{
    box-shadow: 0px 3px 13px 0 rgb(0 0 0 / 10%);
    border-radius: 15px;
    /* border: 1px solid #6e317a; */
    /* cursor: pointer; */
}
.subscription, .cardInfo{
    min-height: 250px;
}
.payAsYouEarn{
    padding: 25px 50px;
    margin-left: 2px;
}
.subscription{
    padding: 10px 15px;
    margin-left: 10px;
}
.cardInfo{
    padding: 15px;
    margin-right: 10px;
}
</style>