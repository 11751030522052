<template>
  <div class="Dealership">
    <!-- Intergrations -->
    <div class="accordion" id="queueSetting">
      <div class="card">
        <div class="card-header" id="queueSettingHeading">
          <h2 class="mb-0">
            <button class="btn btn-link" type="button" data-bs-target="#collapseOne31" aria-expanded="true" aria-controls="collapseOne31">
              <div class="detais-title funnel-title">
                <h3>
                  <img class="img-fluid arrow-down"
                    src="../../../assets/images/dashboard/arrow-d.svg"
                    alt=""
                  />Queue Setting
                </h3>
              </div>
            </button>
          </h2>
        </div>

        <div
          id="collapseOne31"
          class="collapse show"
          aria-labelledby="queueSettingHeading"
          data-bs-parent="#queueSetting"
        >
          <div class="card-body">
            <div class="row">

              <div class="col-md-6">
  <label class="switch me-1">
    <input
      v-model="queue_status"
      type="checkbox"
      true-value="1"
      false-value="0"
      v-on:change="changeQueueState()"
    />
    <span class="slider round"></span>
  </label>
  <template v-if="queue_status === 1">
    When you switch OFF, queue acceptance is disabled.
  </template>
  <template v-else>
    When you switch ON, you begin accepting the queue.
  </template>
</div>

<div class="col-md-6">

  <label class="switch me-2">
    <input
      v-model="queue_virtual"
      type="checkbox"
      true-value="1"
      false-value="0"
      v-on:change="changeQueueState()"
      :disabled="queue_status == 0 ? false : true"
    />
    <span class="slider round"></span>
  </label>
 
When you switch ON, you will only accept walk-in queues.
</div>
  
</div>
            
          </div>
          <!-- <button @click="sendMessage">Test Twilio</button> -->

        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      queue_status: 1,
      queue_virtual: 1,
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    changeQueueState() {
      let thiss = this;
      if(thiss.queue_status == 0){
         thiss.queue_virtual == 0;
      }
      axios
        .get("updateQueueSettings/" + thiss.queue_status + "/" + thiss.queue_virtual )
        .then(function (response) {
          //console.log(response.data.data)
          thiss.employees = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
    },
    getSettings() {
      let thiss = this;
      axios
        .get("getUserSetting")
        .then(function (response) {
          thiss.queue_status = response.data.data.queue_status;
          thiss.queue_virtual = response.data.data.queue_walkin;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
    },
    sendMessage() {
      axios
        .get("test_twilio")
        .then(function (response) {
    console.log("response twil", response.data)
         
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
    },
  },
};
</script>